<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="점검기간"
            name="period"
            v-model="searchParam.period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept 
            type="search" 
            label="주관부서" 
            name="deptCd" 
            v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="stepItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="진행상태"
            v-model="searchParam.hcfSelfCheckCompleteFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        순회 점검
        <div class="card-more">
            <q-btn-group outline >
              <c-btn label="등록" :editable="editable" icon="add" @btnClicked="onItemClick" />
            </q-btn-group>
        </div>
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          title=""
          class="qtable-card"
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <div :class="['text-grid-etc', 'grid-status-div', getColorStatus(props.row.hcfSelfCheckCompleteFlag)]">
                  {{ $language(props.row.hcfSelfCheckCompleteFlagName) }}
                </div>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.facilityName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.weeks + $language('주') }} | {{ props.row.hcfFacilityTypeName }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">{{$language('데이터가 존재하지 않습니다.')}}</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <!-- <c-table
      ref="table"
      title="시설 자체점검 목록"
      tableId="facilitySelfCheck"
      :columns="grid.columns"
      :data="grid.data"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      @linkClick="linkClick"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add_circle" @btnClicked="addFacility" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table> -->
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import mixinFooterSearch from '@/js/mixin-footer-search-common'
export default {
  name: 'hazard-facility-self-check',
  mixins: [mixinFooterSearch],
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        period: [],
        deptCd: null,
        hcfSelfCheckCompleteFlag: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'selfCheckName',
            field: 'selfCheckName',
            label: '점검명',
            align: 'left',
            style: 'width:250px',
            type: 'link',
            sortable: false,
          },
          {
            name: 'facilityName',
            field: 'facilityName',
            label: '시설명',
            align: 'left',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'facilityNo',
            field: 'facilityNo',
            label: '시설관리번호',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'hcfFacilityTypeName',
            field: 'hcfFacilityTypeName',
            label: '시설유형',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'selfCheckDate',
            field: 'selfCheckDate',
            label: '점검일자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'hcfSelfCheckCompleteFlagName',
            field: 'hcfSelfCheckCompleteFlagName',
            label: '진행상태',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '주관부서',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'selfCheckUserName',
            field: 'selfCheckUserName',
            label: '점검자',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      stepItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '완료' },
      ],  
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.env.facilitySelfCheck.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    addFacility() {
      this.linkClick(null);
    },
    linkClick(evt, row) {
      this.popupOptions.title = '시설 자체점검 상세'; // 시설 자체점검 상세
      this.popupOptions.param = {
        hcfHazardFacilitySelfCheckId: row ? row.hcfHazardFacilitySelfCheckId : '',
      };
      this.popupOptions.target = () => import(`${'./hazardFacilitySelfCheckDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'Y': // 점검완료
          cls = 'txt-box-grid text-positive-box';
          break;
        default: // 점검중
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
  }
};
</script>
